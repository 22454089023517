exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-channel-comparision-tsx": () => import("./../../../src/pages/channel-comparision.tsx" /* webpackChunkName: "component---src-pages-channel-comparision-tsx" */),
  "component---src-pages-resources-search-tsx": () => import("./../../../src/pages/resources/search.tsx" /* webpackChunkName: "component---src-pages-resources-search-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-career-tsx": () => import("./../../../src/templates/career.tsx" /* webpackChunkName: "component---src-templates-career-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-downloadable-resource-tsx": () => import("./../../../src/templates/downloadable-resource.tsx" /* webpackChunkName: "component---src-templates-downloadable-resource-tsx" */),
  "component---src-templates-event-registration-tsx": () => import("./../../../src/templates/event-registration.tsx" /* webpackChunkName: "component---src-templates-event-registration-tsx" */),
  "component---src-templates-executive-team-tsx": () => import("./../../../src/templates/executive-team.tsx" /* webpackChunkName: "component---src-templates-executive-team-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-resources-tsx": () => import("./../../../src/templates/resources.tsx" /* webpackChunkName: "component---src-templates-resources-tsx" */)
}

